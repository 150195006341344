module.exports = {
  searchPlaceholder: '¿Qué buscas?',
  relatedCategories: 'CATEGORÍAS RELACIONADAS',
  relatedBrands: 'MARCAS RELACIONADAS',
  product: 'Product',
  CATEGORIES: 'Categorías',
  WELCOME_LABEL: 'Bienvenid@,',
  MENU: 'Menu',
  FILTERS_TITLE: 'Filtrar por:',
  ADD_TO_CART: 'VER PRODUCTO',
  SAVE: 'save',
  FILTERS_TITLE_MOBILE: 'Filtrar',
  FILTERS_AVAILABILITY_TITLE_MOBILE: 'Tipo de entrega',
  CLEAR: 'Cerrar',
  CLEAN: 'Limpiar',
  READY: 'Listo',
  HOME: 'Home',
  LOCATION_DIALOG_HEADER: 'Cambia tu región',
  LOCATION_DIALOG_BODY: 'Selecciona la región donde quieres ver la disponibilidad de productos.',
  REGION: 'Región',
  SEARCH_RESULTS_FOR: 'Resultados de la búsqueda',
  OF: 'de',
  RESULTS: 'Resultados',
  NEXT: 'Siguiente',
  PREVIOUS: 'Anterior',
  LOGIN_LABEL: 'Inicia Sesión',
  MY_ACCOUNT_LABEL: 'Mi Cuenta',
  SUBSCRIPTION_EMPTY_ERROR: 'Por favor ingresa un mail',
  SUBSCRIPTION_INVALID_EMAIL_ERROR: 'Debes ingresar un mail valido',
  SUBSCRIPTION_ACCEPT_TERMS: 'Debes aceptar los terminos y condiciones',
  MY_ACCOUNT: 'Mis ordenes',
  MY_ORDERS: 'Mis órdenes',
  MY_ORDERS_DESC: 'Aquí podrás revisar el estado de tus órdenes.',
  TRACK_ORDER: 'Seguimiento de mi orden',
  BAG: 'Bolsa',
  HELP: 'ayuda',
  A11Y_LOCATION_LABEL: 'location icon',
  VIEW_PREPAID: 'Ver Prepago',
  VIEW_PLAN: 'Ver Plan',
  CHOOSE_VARIANTS: 'ELIGE TUS OPCIONES',
  NO_ACCOUNT_LABEL: '¿No tienes cuenta?',
  REGISTER_LABEL: 'Registrate',
  A11Y_USER_LABEL: 'user info',
  LOG_OUT_LABEL: 'Cerrar sesión',
  ORDER_BY: 'Ordenar',
  CLEAR_FILTERS: 'Quitar filtros',
  SELECTED_FILTERS: 'Filtro seleccionado',
  CHOOSE_LOCATION: 'Selecciona tu regió',
  VENDOR_LABEL: 'Vendedor',
  VENDOR_LOGOUT_LABEL: 'Cerrar sesión',
  VENDOR_NEW_CUSTOMER_SEARCH_LABEL: 'Buscar nuevo cliente',
  VENDOR_CLIENT_LABEL: 'Cliente',
  VENDOR_STORE_LABEL: 'Tienda',
  VENDOR_HOME_LABEL: 'VENDOR HOME',
  VENDOR_SCAN_LABEL: 'Scan',
  PAGE_TITLE: 'falabella.com - Bienvenidos a Nuestra Tienda Online',
  META: {
    DESCRIPTION:
      'De forma f&aacute;cil y segura compra en Falabella.com Celulares, Tecnolog&iacute;a, Electrodom&eacute;sticos, Muebles, Tenis, Zapatos, Colchones y mucho m&aacute;s.',
    OG: {
      COUNTRY: 'CO',
      SITENAME: 'FalabellaCO',
    },
  },
  LOGIN_LOGIN: 'Inicia sesión',
  LOGIN_EMPTY_EMAIL: 'Por favor ingresa tu e-mail.',
  LOGIN_INVALID_EMAIL: 'Formato de email inválido nombre@ejemplo.com',
  LOGIN_EMAIL_PLACEHOLDER: 'Ingresa tu e-mail',
  LOGIN_PASSWORD_PLACEHOLDER: 'Ingresa tu contraseña',
  LOGIN_FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
  LOGIN_LOG_IN: 'Iniciar sesión',
  LOGIN_OR: 'ó',
  LOGIN_FACEBOOK: 'Ingresa Con Facebook',
  LOGIN_NOT_REGISTERED: '¿No tienes cuenta?',
  LOGIN_SIGN_UP: 'Regístrate',
  LOGIN_INVALID_PASSWORD: 'Formato de contraseña inválido',
  RECOMMENDED_PRODUCT_LABEL: 'Clientes que vieron este producto tambieñ vieron',
  CMRAPERTURATEXT_REQUEST: 'Solicita ahora tu CMR y',
  CMRAPERTURATEXT_BENEFITS: '¡descubre un mundo de beneficios!',
};
