const siteConfig = {
  colorGroupMapping: {
    NARANJO: '#fc930a',
    ROJO: '#ba2d1e',
    'AZUL CLARO': '#94bfdc',
    CELESTE: '#94bfdc',
    BEIGE: '#cfb174',
    NEGRO: '#000000',
    BLANCO: '#ffffff',
    AZUL: '#0b108c',
    GRIS: '#dddddd',
    FUCSIA: '#d90073',
    MORADO: '#6d165a',
    VERDE: '#519548',
    ROSADO: '#fcd9e5',
    CREMA: '#fff8d4',
    BURDEO: '#5f021f',
    CAFÉ: '#8e5b3e',
    AMARILLO: '#fdec6f',
  },
  textDictionary: {
    COMBO_GTD_MANQUEHUE_LABEL: 'GTD Manquehue',
    HOME_DELI_AVAILABLE_RESULT: 'Fechas y horarios de despacho disponibles',
    CMRAPERTURATEXT_SAVE: 'AHORRA',
    ENVIO_GRATIS_LABEL_AMOUNT: '$59.990',
    CMRAPERTURATEXT_OPEN_YOUR_CMR: '¿Aún no tienes tu tarjeta CMR?',
    SPONSORED_PRODUCT_SLOTS_APP: '[0, 14.28, 42.8, 71.2]',
    PRODUCT_WITHOUT_RESULT_TITLE: 'El producto que estás buscando ya no está disponible.',
    PRICE_RANGE_FILTER_BUTTON: 'Filtrar',
    CMR_INSTALLMENT_ERROR_MESSAGE: 'Error al cargar. Intenta nuevamente.',
    VENDOR_LOADING_LABEL: 'Buscando..',
    SIZE_CHART_NAME: 'TABLA DE TALLAS',
    CHAT_BOT: 'ChatBot',
    BUNDLED_PRODUCT_TOTAL_PRICE_LABEL: 'Llévalos por',
    VENDOR_SCAN_LABEL: 'Scan',
    SIZE: 'TALLA',
    VIEW_RESULTS_FACET_MODAL: 'Ver resultados',
    PICKUP_INSTORE_AVAILABLEFROM: 'Disponible a partir',
    CATEGORIES: 'Categorías',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_REQUEST_CALL: 'https://fconnect.falabellatienda.com/web-bff/solicita/llamada',
    ADDING_TO_CART_1: 'Agregando',
    VENDOR_CLIENT_LABEL: 'Cliente',
    SELECT_VARIANT_CHOOSE_OPTIONS: 'ELIGE TUS OPCIONES',
    VENDER_CANCEL_BUTTON_LABEL: 'CANCELAR',
    CURRENT_NUMBER_PLACEHOLDER: '9 8765 4321',
    SEARCH_WITHOUT_RESULT_DESCRIPTION:
      'Tal vez su búsqueda fue demasiado específica, intente buscar con un término más general.',
    CONNECT_ASSESSMENT_FORM_TERMS: 'Términos y condiciones',
    PAGE_TITLE: 'Falabella.com - Mejor Compra Online',
    VIEW_PLANES_LABEL_REBRANDING: 'Ver planes',
    DESPACHO: 'Despacho',
    SEE_MORE_FACET_VALUES: '+ Ver más',
    CONTRACT_PLAN_REBRANDING: 'Contratar plan',
    ACCUMULATE: 'Acumula hasta',
    LOCATION_DIALOG_HEADER: 'Cambia tu región',
    CONNECT_CLICK_TO_GET_CALLED_UNAVAILABLE_TIME:
      'Nuestros ejecutivos no están disponibles en este momento. Te contactaremos próximamente para que puedas escoger el mejor plan para ti.',
    COLORS_AVAILABLE: 'COLORES DISPONIBLES',
    ADD_TO_BAG: 'Agregar al Carro',
    PREVIOUS: 'Anterior',
    SUBSCRIPTION_EMPTY_ERROR: 'Por favor ingresa un mail',
    A2C_EXT_WARRANTY: 'Ver beneficios',
    SUBSCRIPTION_ACCEPT_TERMS: 'Debes aceptar los terminos y condiciones',
    BUNDLED_PRODUCT_LABEL: 'Juntos van mejor',
    CHIP: 'CHIP',
    COOKIES_PAIR_TO_REMOVE: 'bi_ab::oltr_enabled',
    REGIONS: 'Regiones',
    PDP_STARTS_FROM: 'A partir de: ',
    LOGIN_PASSWORD_PLACEHOLDER: 'Ingresa tu contraseña',
    TEAM_FROM_LABEL: 'Equipo con plan desde',
    CLEAR_FILTERS: 'Quitar filtros',
    WITHDRAW_AT_STORE_LABEL_AMOUNT: '16:00 horas',
    FALLBACK_SEO_COLLECTION_TITLE: '$xlp_name$',
    EMPTY_SEARCH_ERROR_TITLE: 'Busqueda Vacia',
    PDP_NO_THANKS: 'Sin garantía extendida',
    STORE_PICKUP_SAVE: 'GUARDAR TIENDA',
    FALLBACK_SEO_CATEGORY_TITLE_BRAND: '$xlp_name$ $facet_value$',
    MONTHLY_PLAN_LABEL: 'Plan mensual desde',
    AVAILABILITY: 'Disponibilidad',
    HOME_DELI_TITLE: 'Despacho a domicilio',
    CANCEL: 'Cancelar',
    EMPTY_SEARCH_ERROR_MESSAGE: 'INGRESA LA PALABRA, PRODUCTO O CÓDIGO DE LO QUE QUIERES BUSCAR',
    RETURN_POLICY_MOBILE_HEADER: 'Politica de cambios y devoluciones',
    CONNECT_ASSESSMENT_FORM_OPTION_NEW_NUMBER: 'Número nuevo',
    PRICE_RANGE_TO: 'Hasta',
    PRODUCT_ADDED: 'Producto(s) agregado',
    CHOOSE_LOCATION: 'Selecciona tu región',
    HOME_DELI_SEE_MORE_OPTIONS: 'Ver más opciones',
    HOME_DELI_OUTOFSTOCK: 'No disponible para despacho a domicilio',
    CHECK_ANOTHER_STORE: 'Revisar otra tienda',
    VENDOR_STORE_LABEL: 'Tienda',
    CONNECT_PLANS_LABEL: 'Planes',
    PLANS_CLICK_TO_GET_CALLED_FORM_PH_NAME: 'JUANA RÍOS',
    COLOR: 'Color',
    FALLBACK_SEO_BRAND_DESCRIPTION:
      'Compra online artículos y productos de $xlp_name$, conoce las diferentes opciones que tenemos para ti en tus marcas favoritas.',
    A2C_ERROR_AL013: 'No se pudo agregar el ID de producto',
    CMR_CALCULATOR_ERROR_LOADING_TRY_AGAIN: 'Error al cargar. Intenta nuevamente.',
    RETURN_POLICY_INFO_SOFTLINE: 'Tienes 30 días desde que lo recibes. ',
    SELLER_SKU_ID: 'Cód. tienda',
    NEW_BADGE: 'Nuevo',
    ADD_TO_CART: 'Ver producto',
    LOADER_MESSAGE: 'Estamos procesando tu solicitud. Por favor espera un momento',
    CONNECT_GO_BACK: 'Volver atrás',
    COMBO_WOM_LABEL: 'WOM',
    SEE_COMMENTS: 'Ver comentarios',
    TYPE_C_PICKUP_INSTORE_OUTOFSTOCK_MKP: 'No Retiro en tienda',
    CONNECT_ASSESSMENT_FORM_SERIAL_NUMBER_TEXT: 'NÚMERO DE SERIE',
    MORE_PRODUCTS_FROM_COLLECTION: 'Juntos van mejor',
    SELECTED_FILTERS: 'Filtro seleccionado',
    A2C_ERROR_REMOVE_ITEM: 'Error al eliminar el artículo del carrito',
    SELECTION_TIENDA: 'Selecciona la talla para ver disponibillidad en tienda:',
    PICKUP_INSTORE_PLEASE_SELECT: 'Selecciona un punto de retiro',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_BRAND:
      'Adquiere $xlp_name$ $facet_value$ de manera segura y fácil en falabella.com Conoce nuestro cátalogo online y haz la mejor elección.',
    CLOSE_A11Y_LABEL: 'Close',
    VENDOR_INVALID_CODE_LABEL: 'Lo sentimos, no hemos podido encontrar el producto escaneado.',
    LOGIN_LOG_IN: 'Iniciar sesión',
    TYPE_C_HOME_DELI_OUTOFSTOCK: 'No disponible para despacho a domicilio',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_TYPE:
      'Encuentra lo último de $facet_value$ en $xlp_name$. Elige el modelo perfecto para ti y realiza tus compras online de manera segura en falabella.com',
    PLANS_CARD_SUBTITLE: 'Ingresa tus datos y descubre las mejores ofertas para ti. (Necesitas tu cédula de identidad)',
    MY_ACCOUNT_LABEL: 'Mi Cuenta',
    SELECTED_FILTERS_MOBILE: 'Filtros:',
    SEE_MORE: 'ver más',
    AVAILABILITY_DOWNLOADABLE: 'Descargable',
    ONLY_BUY_AT_STORE: 'Compra solo en tienda',
    MARKET_PLACE: 'Marketplace',
    PICKUP_INSTORE_SUB_PREFIX: 'En',
    COMBO_MOVIL_FALABELLA_LABEL: 'Movil Falabella',
    CONNECT_ASSESSMENT_FORM_TITLE: 'Datos para la evaluación',
    CONNECT_AGENT: 'EvalFalConnectj6S9B30n104f94g',
    PRODUCT_OUTOFSTOCK: '¡Qué mal! Justo se agotó',
    ERROR_SERVICE_TRY_AGAIN:
      '¡Intenta nuevamente! la conexión tardó más de lo esperado. Vuelve a intentar y revisa los planes que tenemos disponibles para ti.',
    INTERNATIONAL_SHIPPING_XLP: 'Compra internacional',
    MAXIMUM_QUANTITY_LIMIT_LABEL: 'La cantidad ingresada excede el stock del producto.',
    CONNECT_ASSESSMENT_FORM_ERROR_CLIENT: 'ESTIMADO CLIENTE',
    VENDOR_NEW_CUSTOMER_SEARCH_LABEL: 'Buscar nuevo cliente',
    EXTENDED_WARRANTY_LABEL: 'Garantía extendida',
    PLANS_CLICK_TO_GET_CALLED_FORM_NUMBER: 'Número de contacto',
    FALLBACK_SEO_BRAND_TITLE: '$xlp_name$',
    PLANS_CLICK_TO_GET_CALLED_OK_SUBTITLE:
      'En minutos un ejecutivo estará contactándose para que puedas escoger el mejor plan para ti.',
    WANT_THIS_PLAN_BUTTON_REBRANDING: 'Quiero este plan',
    HOME_DELI_DATE_RANGE: 'Opción rango de fechas:',
    OCULTAR_LABEL: 'Ocultar',
    HOME: 'Home',
    searchPlaceholder: '¿Qué buscas?',
    CONNECT_CMR_SERVICE_CALL: 'https://fconnect.falabellatienda.com/api-txd/cmr/consultaCmrInternet',
    COMBO_PHONE_PRICE_LABEL: 'Precio de equipo',
    PRICE_NO_RESULTS_ERROR_LABEL: 'Rango sin resultados. Por favor ingresa otros valores.',
    RETURN_POLICY_LINK_TEXT: 'Conoce los plazos y exclusiones',
    VENDOR_PRICE_LABEL: 'Precio',
    PLANS_CLICK_TO_GET_CALLED_SUBTITLE:
      'Si necesitas asistencia de un especialista ingresa tus datos de contacto y te llamaremos dentro de unos minutos.',
    VENDOR_BARCODE_CENTER_LABEL: 'Centra tu código de barras y evita mover tu teléfono.',
    relatedCategories: 'CATEGORÍAS RELACIONADAS',
    PLANS_CLICK_TO_GET_CALLED_BUTTON_REBRANDING: 'Comunícate ahora',
    SEE_LESS: 'ver menos',
    PDP_BI_AB_COOKIE: 'pdp_ab',
    CONNECT_OPERATOR_SERVICE_CALL:
      'https://fconnect.falabellatienda.com/api-txd/credito/consultaInformacionCreditoInternet',
    WITHDRAWAL_AT_STORE_TIMELINE: 'retira hoy',
    SEARCH_RESULTS_FOR: 'Resultados de la búsqueda',
    ADDITIONAL_SERVICES_LABEL: 'Servicios adicionales',
    VOLVER_A_REVISAR: 'Volver a revisar',
    PLANS_CARD_ONLINE: 'ONLINE',
    COMUNA: 'Comuna',
    LOG_OUT_LABEL: 'Cerrar sesión',
    GOOGLE_ELEMENT_UNIQUE_ID: 'div-gpt-ad-3024305',
    CMRONLINESUCCESSNOTENROLLED:
      'Tu tarjeta CMR fue creada, pero no pudo ser anadida a tu cuenta, favor dirígete a una sucursal CMR',
    HOW_WORKS_CONNECT: '¿Cómo funciona Falabella Connect?',
    EXPERT_ADVICE: 'ASESORÍA DE EXPERTOS',
    NEED_RUT: 'Tenemos ofertas especiales. Solo necesitamos tu RUT para mostrártelas.',
    IOS_VERSION_SINGLE_SELECT: '2.5.24',
    CONNECT_CLICK_TO_GET_CALLED_BUTTON_THIRD_MODULE: '¡Te llamamos!',
    SLP_BI_AB_COOKIE: 'search_ab',
    READY: 'Aplicar',
    SHOW_OFFER_BUTTON: 'VER TODA LA OFERTA',
    LOW_STOCK_WARNING: 'Los productos podrían agotarse próximamente. Cómpralos pronto.',
    LOGIN_INVALID_PASSWORD: 'Formato de contraseña inválido',
    CHOOSE_VARIANTS: 'ELIGE TUS OPCIONES',
    FAILED_ADDING_TO_CART_SERVICE:
      'En este momento no podemos agregar el Servicio adicional seleccionado. Por favor inténtalo nuevamente más tarde.',
    MODEL_CHARACTERISTICS_PREFIX_MOBILE: 'Medidas de modelo',
    AB_TEST_MATCH_VALUE: 'rltr_enabled',
    REGION: 'Región',
    ADD_X_ITEM_TO_BAG_REBRAND: 'Agregar al Carro',
    LOGIN_FACEBOOK: 'Ingresa Con Facebook',
    CONNECT_ERROR_MESSAGE_BUTTON: 'Aceptar',
    COMBO_OPERATOR_LABEL: 'Operador',
    FALLBACK_SEO_SELLER_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FALLBACK_SEO_SELLER_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    CONNECT_IMPORTANT_THINGS:
      ' Esperamos ofrecerte los mejores planes y equipos, pero antes queremos recordarte dos cosas importantes:',
    LOGIN_FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    OTHER_SEARCHES_LABEL: 'Otras posibles búsquedas:',
    CMRAPERTURATEXT_USING_CMR: 'USANDO TU CMR',
    PLANES: 'PLANES',
    CONNECT_ASSESSMENT_FORM_RETRY_BUTTON_REBRANDING: 'Probar con otro RUT',
    PLANS_CLICK_TO_GET_CALLED_OK_TITLE_END: 'recibimos tu solicitud!',
    COMBO_GIGAS_LABEL: 'Gigas',
    INCORRECT_SEARCH_ERROR_TITLE: 'Busqueda Incorrecta',
    TYPE_C_PICKUP_INSTORE_OUTOFSTOCK: 'No disponible para retiro en tienda',
    ERROR_COLOR_NOT_SELECTED: 'Seleccionar tu color para contratar el plan',
    sellerMuteValue: 'LINIO',
    CONNECT_OPERATORS_OPTION: 'Selecciona un operador',
    CHAT_BOT_NAME_AR: 'Lucía',
    COMBO_ENTEL_LABEL: 'Entel',
    CMR_REMINDER: 'Planes disponibles solo con tu tarjeta CMR',
    SAVE: 'Guardar',
    SHIPPING_LABELS: 'Llega hoy,Llega mañana,Envío gratis',
    FAILED_ADDING_TO_CART_LABEL: 'Este producto ya no está disponible.',
    CONNECT_ASSESSMENT_FORM_QUE_QUIERES_HACER: '¿QUÉ QUIERES HACER CON TU NÚMERO?',
    product: 'Product',
    COMBO_VTR_LABEL: 'VTR',
    CLICK_TO_GET_CALL_BUTTON: '¿NECESITAS ASESORÍA?',
    MY_ACCOUNT: 'Mis ordenes',
    COMBO_PREPAGO_LABEL: 'Prepago',
    HOW_WORKS_CONNECT_TITLE: 'Términos y condiciones Falabella Connect',
    HOME_DELIVERY_SHIPPING: 'Despacho a domicilio.',
    IOS_FACET_SUPPORT_VERSION: '10.0.0',
    SAME_DAY_FACET_MINUTES: 59,
    RETURN_POLICY_LINK_URL: 'https://www.falabella.com/falabella-cl/page/excepciones-devoluciones-cambios',
    FREE_SHIPPING_PRICE_3P: '19990',
    WANT_THIS_PLAN_BUTTON: 'QUIERO ESTE PLAN',
    TYPE_C_HOME_DELI_TITLE_MKP: 'Envío a domicilio',
    INCORRECT_SEARCH_ERROR_MESSAGE: 'INGRESA LA PALABRA, PRODUCTO O CÓDIGO DE LO QUE QUIERES BUSCAR',
    COMBO_ORDER_BY_LABEL: 'Ordenar por',
    MENU: 'Menu',
    CONNECT_ASSESSMENT_FORM_RUT_TEXT: 'RUT',
    CLICK_TO_GET_CALL_BUTTON_SUBTITLE: '¡Te llamamos! Haz click',
    COMBO_PLAN_PRICE_LABEL: 'Precio de plan',
    MAX_FACET_LIMIT: 1500,
    COMBO_MOVISTAR_LABEL: 'Movistar',
    ASSOCIATED_SELLER_INFO:
      'Este es un producto publicado directamente por un vendedor nacional o internacional y cuenta con el respaldo de Falabella. En algunos casos podría tener plazos de despacho diferentes a los habituales.',
    ANDROID_FACET_SUPPORT_VERSION: '10.0.0',
    CURRENT_NUMBER_PREFIX: '+56 ',
    PICKUP_INSTORE_SEE_AVAILABILITY: 'Ver retiro',
    REVIEW_CONTRACT: 'Revisa el mandato y contrato. Elige la modalidad de firma que más te acomode.',
    OUT_SERVICE:
      'En estos momentos no fue posible atender su solicitud. Por favor vuelve a intentar dentro de unos minutos.',
    CONNECT_IFRAME_WIDTH: 1080,
    CMRAPERTURATEXT_DONT_HAVE_IT: '¿Aún no la tienes?',
    SELECT_PROMOTION_TEXT: 'Seleccionar Promoción',
    CONNECT_PLANS_CMR_ERROR_BLACK_LIST: 'No califica para comprar planes con falabella connect.',
    RETURN_POLICY_INFO_HARDLINE: 'Tienes 30 días desde que lo recibes. ',
    CONNECT_ASSESSMENT_FORM_TERMS_LINK:
      'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt03833e32eb76ba73/Terminos-y-condiciones-cotizar-telefonia.pdf',
    PLANS_DETAILS_FREE_MINUTES_LABEL: 'MINUTOS LIBRES',
    UNITS_AVAILABLE_LABEL: 'Unidades disponibles',
    THIRD_PARTY_SELLER_INFO:
      "Este producto está publicado directamente por un vendedor nacional y cuenta con el respaldo de <b style='font-weight: bold'>Falabella.com</b>. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.",
    CONNECT_AUTENTIA_SERVICE_CALL: 'https://fconnect.falabellatienda.com/api-txd/validaCliente/validaCi',
    LOGIN_OR: 'ó',
    REQUEST_PRODUCT: 'Solicitar Producto',
    CONTACT_OUR_SPECIALISTS: 'Te asesoramos por chat o teléfono',
    INTERNATIONAL_DELIVERY: 'Compra internacional.',
    HOME_DELI_SAVE: 'GUARDAR DIRECCIÓN',
    CONNECT_QUESTION_THIRD_MODULE: '¿Tienes dudas?',
    CHOOSE_OPTION: 'Selecciona una opción',
    FALLBACK_SEO_COLLECTION_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    REQUEST_ACCEPTED: 'SOLICITUD ACEPTADA',
    CMR_MAPPING:
      'WwogIHsKICAgICJ0aXRsZSI6ICJDb24gdGFyamV0YSBDTVIiLAogICAgImRpdmlkZUZhY3RvciI6IDIwMCwKICAgICJsb2dvIjogImljb25zLmNtclRhcmpldGFJY29uIgogIH0sCiAgewogICAgInRpdGxlIjogIkNvbiBEw6liaXRvIEJhbmNvIEZhbGFiZWxsYSIsCiAgICAiZGl2aWRlRmFjdG9yIjogNTAwLAogICAgImxvZ28iOiAiY29ucy5iYW5jb0ljb24iCiAgfSwKICB7CiAgICAidGl0bGUiOiAiQ29uIEN1ZW50YSBGcGF5IiwKICAgICJkaXZpZGVGYWN0b3IiOiA1MDAsCiAgICAibG9nbyI6ICJpY29ucy5mcGF5SWNvbiIKICB9LAogIHsKICAgICJ0aXRsZSI6ICJDb24gdGFyamV0YSBkZSBjcsOpZGl0byIsCiAgICAiZGl2aWRlRmFjdG9yIjogNzUwLAogICAgImxvZ28iOiAiaWNvbnMuY3JlZGl0b0ljb24iCiAgfSwKICB7CiAgICAidGl0bGUiOiAiQ29uIHRhcmpldGEgZGUgZMOpYml0byIsCiAgICAiZGl2aWRlRmFjdG9yIjogNzUwLAogICAgImxvZ28iOiAiaWNvbnMuZGViaXRvSWNvbiIKICB9Cl0=',
    TYPE_C_PICKUP_INSTORE_TITLE: 'Disponible para retiro en tienda',
    PICKUP_OR_DELIVERY:
      'Retira tu compra en cualquiera de nuestras tiendas. Si firmaste en línea, recibe tu Smartphone donde quieras y disfrútalo.',
    FALLBACK_SEO_COLLECTION_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FILTERS_TITLE_REBRANDING_MOBILE: 'Filtrar por:',
    LOGIN_INVALID_EMAIL: 'Formato de email inválido nombre@ejemplo.com',
    HIRE_YOUR_PLAN: 'Datos para contratar tu plan',
    PICKUP_INSTORE_INFO_CAN_VARY: 'La información de disponibilidad puede variar durante lacompra.',
    PREPAID_DEVICE: 'Equipo prepago desde',
    CURRENT_NUMBER_LABEL: 'Número Actual',
    FALLBACK_SEO_CATEGORY_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    SKU_SEARCH_REGEX:
      '(^[0-9]{5,})+$|(^prod[0-9]+$)|(^sku[0-9]+$)|(^[0-9]{4,}X+$)|(^[0-9]{7,})+C$|C[0-9]{1,2}$|(^[0-9]{3,6})+XC$|XC[0-9]$',
    TYPE_C_PICKUP_INSTORE_TITLE_MKP: 'Retiro en tienda',
    BIG_TICKETS_UNAVAILABLE:
      'Debido a su tamaño, este producto no está disponible para retiro en tienda, pero nosotros te lo llevamos sin problemas, intenta la opción ',
    SEARCH_WITHOUT_RESULT_TITLE: 'Lo sentimos, no encontramos resultados para',
    FALLBACK_SEO_CATEGORY_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    REGISTER_LABEL: 'Registrate',
    PICKUP_INSTORE_STORES_AVAILABLE: 'Puntos de retiro disponibles',
    CMSIZE: 'cm',
    BUY_CONNECT_ONLINE: '¡Ahorra tiempo! Compra un Smartphone y contrata un plan, todo en línea y de una sola vez.',
    OF: 'de',
    PICKUP_INSTORE_CHECK_ANOTHER_STORE: 'Revisar otra tienda',
    SPECIFICATIONS_LABEL: 'Especificaciones',
    CHECK_THE_OFFERS: '¡Revisa las ofertas!',
    VIEW_MORE_SPECIFICATONS: 'Ver más especificaciones',
    FALLBACK_SEO_CATEGORY_DESCRIPTION:
      'Compra online $xlp_name$ de tu marca favorita en falabella.com Conoce nuestro catálogo de $xlp_name$ y elige la opción perfecta para ti.',
    ADDING_TO_CART: 'Agregando...',
    FALLBACK_SEO_SELLER_TITLE: '$xlp_name$',
    WHY_PDP: '¿Por qué?',
    EVALUATE_PLANS_BUTTON_REBRANDING: 'Revisar planes',
    COMBO_MINUTES_LABEL: 'Minutos',
    A2C_ERROR_CART_CONFIG_BREACH: 'Se superó la cantidad máxima permitida por producto',
    ITEM_ADDED_A11Y_LABEL: 'Item Added',
    SEARCH_IN_FACETS_LABEL: 'search in facets',
    VIEW_PLANES_LABEL: 'VER PLANES',
    PAY_AT_BOX: 'PAGAR EN LA CAJA',
    SHOW_OFFER_BUTTON_REBRANDING: 'Ver toda la oferta',
    SURVEY_TEXT_ANY_MISTAKE: '¿Encontraste algún error?',
    CLEAN: 'Limpiar Filtro',
    CLEAR: 'Cerrar',
    IDEAL_PLAN_LABEL: 'Tenemos tu plan ideal',
    PICKUP_INSTORE_OUTOFSTOCK: 'No disponible para retiro',
    SELLER_SOLD_BY: 'Vendido por',
    REQUEST_ACCEPTED_MSG_1: 'Favor retirar sus productos en',
    PRODUCT_OUTOFSTOCK_TRY_OTHERS: 'Prueba buscando otros productos en',
    FALLBACK_SEO_SELLER_DESCRIPTION:
      'Compra lo mejor de $xlp_name$ a un solo click. Encuentra esto y todo lo que necesitas en Falabella y Falabella.com!',
    HOME_DELI_SEE_AVAILABILITY: 'Ver envío',
    VENDOR_INFORMATION_NOT_AVAILABLE_LABEL: 'Informacion no disponible',
    ENTER_VALID_PRICE_ERROR_LABEL: 'Ingresa un rango de precios válido.',
    SHARE_FIND_IN: 'Encuéntralo en',
    ACTIVATE_ADD_TO_BAG: 'HABILITAR AGREGAR AL CARRO',
    MY_ORDERS: 'Mis órdenes',
    PLANS_CLICK_TO_GET_CALLED_FORM_NAME: 'Nombre',
    ADD_TO_BAG_MKP: 'Agregar al Carro',
    SAME_DAY_DELIVERY_TIMELINE: 'llega hoy',
    CMRAPERTURATEXT_OPEN_IT: ' Ábrela',
    FAILED_ADDING_TO_CART_BUNDLED_PRODUCTS_LABEL:
      'Has superado la cantidad máxima de unidades permitidas para este producto en el carrito. Por favor, ajusta la cantidad antes de proceder',
    PICKUP_INSTORE_SEE_MORE_OPTIONS: 'Ver más opciones',
    RATINGS_STAR_MORE_TEXT: 'y más',
    CMR_DEFAULT_INSTALLMENTS: '10',
    PLANS_DETAILS_MONTH_LABEL: ' meses',
    SUMMARY_MODAL_NOTICE: 'El valor del plan lo podrás pagar en el sitio web del operador o directamente en la tienda.',
    CONNECT_ERROR_MESSAGE:
      'Lo sentimos, se ha producido un error confirmando tu compra. Por favor, comunícate con nuestro Servicio al Cliente al 223907910 o escríbenos a contacto@falabella.cl',
    CMRAPERTURATEXT_ALREADY_HAVE: 'Pídela y obtén $10.000 de descuento.',
    NEED_HELP_CALL_US_AT: '¿Necesitas ayuda? Llámanos al',
    SELECT_OPERATOR_LABEL: 'Selecciona Chip prepago (incluido):',
    CMRAPERTURATEXT_BENEFITS: 'Ábrela ahora y obtén $10.000 de descuento.',
    PAGE_ERROR_TITLE:
      'Lo sentimos, estamos presentando problemas técnicos. Por favor usa la navegación por categorías.',
    CONNECT_ASSESSMENT_FORM_SERIAL_NUMBER_PLACEHOLDER: '123.123.123',
    relatedBrands: 'MARCAS RELACIONADAS',
    STOCK: 'STOCK',
    MORE_OPTIONS: 'Ver opciones',
    A2C_CONTINUE_SHOPPING: 'Seguir comprando',
    SHARE_I_WANT: '¡Lo quiero!',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_ERROR: '¡Ha ocurrido un error vuelve a intentarlo mas tarde!',
    toggleViewButtonText: 'Visualizar por:',
    THIRD_PARTY_INT_SELLER_INFO:
      "Este producto está publicado directamente por un vendedor internacional y cuenta con el respaldo de <b style='font-weight: bold'>Falabella.com</b>. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.<br/><br/><b style='font-weight: bold'>El precio del producto incluye el costo de envío internacional y pago de impuestos de internación</b> (aduana). Al ser internacional pasa por una inspección y, en algunos casos, esto podría generar atrasos en la entrega.",
    PER_UNIT_PRICE_PREFIX: 'Unidad a',
    SKU_EQUIPMENT: 'SKU Equipo',
    OFFER_PRICE_LABEL: '',
    USSIZE: 'US',
    BACK_ROOM: 'trastienda',
    EVALUATE_PLANS_BUTTON: 'REVISAR PLANES',
    FEATURE_FILTER_MODAL_TITLE: 'Selecciona un filtro',
    VIEW_PREPAID: 'Ver Prepago',
    RECEIVE_AND_ENJOY: '¡Recibe y disfruta!',
    ANDROID_VERSION_SINGLE_SELECT: '2.5.28',
    RETURN_POLICY_HEADING: 'Devuelve gratis por Derecho a retracto o Satisfacción garantizada.',
    CONNECT_IFRAME_URL: 'https://txd-fconnect-prd.appspot.com/#/?data=',
    OWN_SELLER_INFO:
      "Producto publicado directamente por <b style='font-weight: bold; text-transform: capitalize;'>{{tienda}}</b>.",
    CMRAPERTURATEXT_ONTHIS_PURCHASE: 'en esta compra',
    FAILED_ADDING_TO_CART_BUNDLED_PRODUCTS_LABEL_MKP:
      'Has superado la cantidad máxima de unidades permitidas para este producto en el carrito. Por favor, ajusta la cantidad antes de proceder',
    SIZE_CHART: 'Tabla de tallas',
    TEAM: 'EQUIPO',
    PRODUCT_INFORMATION_LABEL: 'Información adicional',
    CART_ASSEMBLY_OPTION_SEC_LABEL: '(Sólo RM radio urbano)',
    VIEW_PLAN_REBRANDING: 'Ver plan',
    PREPAID_OPTION: 'Ver la opción prepago',
    VENDOR_LOGOUT_LABEL: 'Cerrar sesión',
    CONNECT_CLICK_TO_GET_CALLED_END_TIME: '21',
    ADD_TO_CART_TEXT_ALONE: 'Agregar',
    A11Y_VIDEO_LABEL: 'Play Video',
    NEXT_DAY_SHIPPING_MIN_MAX_HOURS: '20,4',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    PICKUP_INSTORE_SAVESTORE_BUTTON: 'GUARDAR TIENDA',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    PLANS_CARD_SUBTITLE_MODAL: 'Ingresa tus datos y encuentra las mejores ofertas para ti, con todas las compañias.',
    ATC_ONLY_BUY_AT_STORE: 'Ver Producto',
    LOGIN_EMAIL_PLACEHOLDER: 'Ingresa tu e-mail',
    VIEW_PLAN: 'Ver Plan',
    ERROR_SERVICE_NO_PLANS: 'Lo sentimos, no se han encontrado planes disponibles en esta evaluación',
    SHOWING_RESULT_FOR_LABEL: 'Mostrando resultados para',
    VENDOR_HOME_LABEL: 'VENDOR HOME',
    PICKUP_INSTORE_AVAILABLE_RESULT: 'Fechas y horarios de despacho disponibles',
    CMRONLINESUCCESS: 'Tu Tarjeta CMR fue añadida a tus medios de pago.',
    MODEL_CHARACTERISTICS_PREFIX: 'Medidas de modelo',
    CONNECT_PLANS_CMR_INVALID: 'Su cédula de identidad se encuentra vencida o numero de serie es incorrecto',
    RECOMMENDED_PRODUCT_LABEL: 'Clientes que vieron este producto tambieñ vieron',
    LOGIN_SIGN_UP: 'Regístrate',
    ITEM_ADDED_IN_CART: 'Producto(s) agregado(s) al carro',
    CONNECT_IMPORTANT_THING_3: 'Si quieres portar tú número a otra compañía tienes que ser el titular de la línea.',
    CONNECT_AUTENTIA_ERROR_MESSAGE: 'Su cédula de identidad se encuentra vencida o numero de serie es incorrecto',
    CONNECT_CLICK_TO_GET_CALLED_BUTTON_THIRD_MODULE_DESKTOP: '¡Te llamamos!',
    SEE_COLLECTION_BUTTON_LABEL: 'VER COLECCIÓN',
    SAME_DAY_DELIVERY_LABEL: 'Si compras hasta las',
    FALLBACK_SEO_CATEGORY_TITLE: '$xlp_name$',
    LOCATION_DIALOG_BODY: 'Selecciona la región donde quieres ver la disponibilidad de productos.',
    CONNECT_ASSESSMENT_FORM_ACCEPT_BUTTON: 'REVISAR PLANES',
    REACONDITIONED_LINK:
      'https://assets.contentstack.io/v3/assets/blt7c5c2f2f888a7cc3/blt6e6552e1707e37fd/productos-reacondicionados.pdf',
    NEED_HELP: '¿Necesitas ayuda?',
    ADD_TO_CART_TEXT_REBRANDING: 'Agregar al Carro',
    CONNECT_IMPORTANT_THING_2:
      'Para contratar un plan necesitamos hacer una previa evaluación financiera. Por esto Promotora CMR Falabella S.A nos proporciona información necesaria para que podamos ofrecerte un plan adecuado y especial para ti. Si todavía no tienes tu CMR no dudes en sacarla.',
    CONNECT_IMPORTANT_THING_1: 'Para contratar tu plan tienes que tener tarjeta',
    CONNECT_ERROR_MESSAGE_BASKET:
      'Lo sentimos, no es posible añadir más productos a la Bolsa. Ya tienes agregado un producto de Falabella Connect. Este tipo de producto debe ser comprado de manera independiente, ya que tiene un despacho preferente.',
    SELECT_OPTION: 'ELIGE TUS OPCIONES',
    CLEAR_FACET_VALUES_MODAL: 'Limpiar filtros',
    HOME_DELI_STORE_PLEASE_SELECT: 'Revisar disponibilidad',
    CONTRACT_PLAN: 'CONTRATAR PLAN',
    UKSIZE: 'UK',
    CONNECT_ASSESSMENT_FORM_ACCEPT: 'Acepto los ',
    PLANS_CLICK_TO_GET_CALLED_FORM_PH_NUMBER: '987654321',
    SKU_PLAN: 'SKU Plan',
    PLANS_DETAILS_FREE_LABEL: 'LIBRES',
    PRODUCT_ORCHESTRATOR_FLOW: 'false',
    PICKUP_INSTORE_NO_RESULT_AVAILABLE: 'Lo sentimos, no se encontraron tiendas disponibles',
    FALLBACK_SEO_BRAND_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_BRAND_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    HIRE: 'CONTRATA TU',
    ADD_TO_CART_TEXT_MKP: 'Agregar al Carro',
    VENDOR_STOCK_LABEL: 'Stock',
    NEXT: 'Siguiente',
    HOME_DELI_SUB_PREFIX: 'A',
    PLAN_REVIEW_LABEL:
      'Revisa los planes disponibles de los distintos operadores que tenemos para ti con este teléfono.',
    ORDER_BY_DESKTOP: 'Ordenar por',
    POD_BOOM_SHIPPING_LABEL: 'Boom',
    A11Y_USER_LABEL: 'user info',
    CMRONLINEDECLINED: 'No se pudo terminar la solicitud. Para mas información dirígete a una sucursal CMR',
    PURCHASE_ONLINE_OUTOFSTOCK: 'No Disponible para compra en internet',
    CONNECT_LEGAL_TEXT:
      'Los términos y condiciones regulan la evaluación comercial de los planes de telefonía móvil ofrecidos por los operadores de telefonía móvil en nuestro sitio web www.falabella.com, que puedes contratar en conjunto con los teléfonos celulares comercializados por Falabella Retail S.A. Puedes revisar el contenido de los términos y condiciones de la evaluación comercial aqui.\n\nPara realizar la evaluación comercial, es necesario que seas titular de una tarjeta CMR, y que ésta haya sido emitida, con al menos, 90 días de antigüedad a la fecha de solicitud de evaluación o contratación del plan de telefonía móvil. Adicionalmente, validaremos la vigencia y la exactitud de los datos de tu cédula de identidad que nos proporciones.\n\nLos productos deben ser comprados de manera independiente y separada de otros productos que puedas tener en tu carro de compras. Si tienes productos adicionales en tu carro de compra, no te preocupes, éstos quedarán guardados para una compra futura.\n\nEl plan de telefonía y el precio del equipo pueden ser pagados con cualquier medio de pago disponible en falabella.com. El descuento en el precio del equipo está sujeto a la contratación del plan de telefonía respectivo y a la firma de un mandato para el cobro en tu tarjeta de crédito CMR, de la diferencia entre el precio del equipo y el precio prepago de este, según corresponda, el cual aplicará si das de baja el plan suscrito o contratas uno más económico dentro de un periodo de 18 meses para cada operador.\n\nDebido a los requerimientos y procesos del operador telefónico, existe un plazo máximo de 15 días para que recibas tu producto.',
    WITHDRAWAL_AT_STORE: 'Retiro en un punto',
    PICKUP_INSTORE_CLOSE_BUTTON: 'CERRAR',
    TRACK_ORDER: 'Seguimiento de mi orden',
    A2C_VIEW_BASKET: 'IR AL CARRO DE COMPRAS',
    VENDOR_PRODUCT_AVAILABLE_LABEL: 'Producto disponible en tienda',
    PRICE_RANGE_FROM: 'Desde',
    CONNECT_ASSESSMENT_FORM_RUT_PLACEHOLDER: '12.345.678-9',
    BOOM_SHIPPING: 'Boom',
    LOGIN_HEADER_DESC: 'Compra más rápido y revisa los detalles de tus órdenes',
    PLAN: 'PLAN',
    PRODUCT_CODE: 'Código del producto',
    SAME_DAY_FACET_HOURS: '15',
    LOGIN_LABEL: 'Inicia Sesión',
    CONNECT_TEXT_THIRD_MODULE:
      'Si necesitas asistencia de un especialista ingresa tus datos de contacto y te llamaremos dentro de unos minutos. Recuerda que también puedes comunicarte directamente llamando al 600 390 4100',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_TOKEN: 'https://fconnect.falabellatienda.com/api-txd/getTokenEncrypt',
    SPONSORED_PRODUCT_SLOTS: '[0, 10.9, 50, 100]',
    LOGIN: 'Inicia sesión',
    ADD_X_ITEM_TO_BAG: 'Agregar los {count} al Carro',
    VENDOR_SCAN_ANOTHER_PRODUCT_LABEL: 'ESCANEAR OTRO PRODUCTO',
    TYPE_C_HOME_DELI_OUTOFSTOCK_MKP: 'No disponible para envío a domicilio',
    PICKUP_INSTORE_TITLE: 'Retira tu compra',
    NO_ACCOUNT_LABEL: '¿No tienes cuenta?',
    SAME_DAY_DELIVERY_LABEL_AMOUNT: '16:00 horas',
    FALLBACK_SEO_CATEGORY_TITLE_TYPE: '$xlp_name$ $facet_value$',
    DY_ALLOWED_SIS: 'sodimac',
    PERFUME_MERCHANT_CATEGORY_IDS: 'J08020101,J08020102,J08050101',
    FILL_YOUR_DETAILS: 'Completa tus datos y firma',
    PLANS_DETAILS_GB_LABEL: 'GB',
    ORDER_BY: 'Ordenar',
    CONNECT_ASSESSMENT_FORM_OPTION_KEEP_NUMBER: 'Mantener número',
    WARNING_A11Y_LABEL: 'Warning',
    ERROR_SERVICE_SAME_OPERATOR:
      'No puedes realizar compra de un plan de tu misma compañía. ¡Revisa la oferta con otros operadores!',
    PURCHASE_ONLINE_TITLE: 'Disponible para compra en internet',
    COMBO_PLAN_PREPAGO_LABEL: 'Plan / Prepago',
    SHARE_LIKE_PRODUCT: 'Me gustó este producto',
    CHOOSE_OPTION_LABEL: 'Opción rango de horario:',
    NO_RESULT_LABEL: 'Lo sentimos, no encontramos resultados para',
    WELCOME_LABEL: 'Bienvenid@,',
    FILTERS_TITLE: 'Filtrar',
    LOGIN_NOT_REGISTERED: '¿No tienes cuenta?',
    RATINGS_STAR_ONLY_TEXT: 'solamente',
    FALLBACK_SEO_SELLER_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_SELLER_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    ERROR_NO_OFFER_PER_OPERATOR:
      'No existen ofertas disponibles con este operador en la actualidad. ¡Revisa la oferta con otros operadores!',
    PLANS_DETAILS_NEED_EVALUATION_LABEL: 'REVISA TUS PLANES',
    CONNECT_CLICK_TO_GET_CALLED_BUTTON_THIRD_MODULE_MOBILE: '¡Venta Telefonica!',
    PROMOTED_FILTER_FOR: 'Filtrar por ',
    COMBO_CLARO_LABEL: 'Claro',
    OK_TO_SHOP_LABEL: 'Ficha obtenida de',
    SURVEY_TEXT_TELL_US: '¡Cuéntanos!',
    HELP: 'ayuda',
    CONNECT_ASSESSMENT_FORM_RETRY_BUTTON: 'PROBAR CON OTRO RUT',
    INTERNATIONAL_SHIPPING: 'Envío Internacional',
    EURSIZE: 'EUR',
    SEE_LESS_FACET_VALUES: '+ Ver menos',
    AVAILABILITY_PDP: 'Disponibilidad de producto',
    TOGETHER_PRODUCTS: 'Van bien juntos',
    GO_BACK_TO_PLANS: 'Volver a planes',
    PLANS_CLICK_TO_GET_CALLED_BUTTON_COMUNICATE: 'COMUNÍCATE AHORA',
    PLANS_CARD_TITLE: 'CONTRATA TU PLAN',
    COMBO_NETLINE_LABEL: 'Netline',
    MY_ORDERS_DESC: 'Aquí podrás revisar el estado de tus órdenes.',
    PICKUP_INSTORE_RESULT_WILL_COME_HERE: 'Tus tienda más cercanas aparecerán aquí',
    SUBSCRIPTION_INVALID_EMAIL_ERROR: 'Debes ingresar un mail valido',
    FALLBACK_SEO_COLLECTION_DESCRIPTION:
      'Encuentra $xlp_name$ de tu marca favorita en falabella.com. Compra online con descuentos y envios gratis en productos seleccionados.',
    EXCLUDE_GSC_CATEGORY_ID_FOR_FREE_SHIPPING:
      'G200401,G200402,G200403,G200405,G200406,G200407,G20040804,G20040809,G20040813,G20040901,G20040906,G20040908,G20040914,G200410,G200411,G200412,G20041303,G20041213,G20041405,G20041406,G20041407,G20041410,G200415,G200416,G200417,G200418,G200419,G20042002,G20042006,G200421,G200422,G200423,G200424',
    CIUDAD_TEXT: 'Partido',
    FILTERS_TITLE_MOBILE: 'Filtrar',
    VENDOR_SCAN_INFO_LABEL: 'El escaneo es automático, evita brillos y reflejos.',
    PLANS_DETAILS_MONTHLY_COST_LABEL: 'Cargo fijo mensual',
    CMR_POINTS: 'CMR Puntos',
    CMRAPERTURATEXT_REQUEST: '¿Aún no tienes tu CMR Banco Falabella?',
    HOME_SHIPPING: 'Envío a domicilio',
    COMBO_SIMPLE_LABEL: 'Simple',
    WITHDRAWAL_AT_STORE_LABEL: 'Si compras hasta las',
    HOME_DELI_RESULT_WILL_COME_HERE: 'Tus fechas más cercanas aparecerán aquí',
    LOGIN_EMPTY_EMAIL: 'Por favor ingresa tu e-mail.',
    COLLECTIONS_TO_SHOW_PLANS: 'connect-products,prueba-connect,falabella-connect',
    ORDER_BY_MOBILE: 'Ordenar',
    AVAILABILITY_SELECT_OPTION: 'Selecciona opción para ver disponibilidad',
    VENDOR_LABEL: 'Vendedor',
    CONNECT_IFRAME_HEIGHT: 713,
    CONNECT_ASSESSMENT_FORM_QUE_ES_ESTO: '¿Qué es esto?',
    COMBO_DEFAULT_LABEL: 'Selecciona',
    ADD_TO_CART_TEXT: 'Agregar al Carro',
    PAGES_LABEL: 'Páginas',
    SELECT_VARIANT_CHOOSE_OPTIONS_REBRANDING: 'Elige tus opciones',
    CMR_Falabella: 'CMR Falabella.',
    WHY: '¿Porqué?',
    FILTERS_AVAILABILITY_TITLE_MOBILE: 'Entrega',
    OWN_INT_SELLER_INFO:
      "Este producto está publicado directamente por {{tienda}} y cuenta con el respaldo de <b style='font-weight: bold'>Falabella.com</b>. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.<br/><br/><b style='font-weight: bold'>El precio del producto incluye el costo de envío internacional y pago de impuestos de internación</b> (aduana). Al ser internacional pasa por una inspección y, en algunos casos, esto podría generar atrasos en la entrega.",
    PUM_AFFIX: ' a ',
    CONNECT_PORTABILITY_REMINDER: 'Si te quieres portar, debes ser el títular de tú número',
    ACCEPT: 'ACEPTAR',
    PLANS_DETAILS_PRICE_LABEL: 'Valor equipo',
    PLANS_CLICK_TO_GET_CALLED_OK_TITLE_SIGN: '¡',
    TOP_SPECIFICATIONS: 'Especificaciones principales',
    SPECIFY_YOUR_SEARCH_LABEL: 'Especifica tu búsqueda:',
    SELECT_OPERATOR_ALERT_LABEL: 'El precio puede variar según Chip prepago y/o color',
    CONNECT_CLICK_TO_GET_CALLED_START_TIME: '09',
    COMBO_PLAN_LABEL: 'Plan',
    AB_TEST_VALUE_OVERRIDE: 'bi_ab__online_ltr_classifier',
    FALLBACK_SEO_COLLECTION_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    A11Y_LOCATION_LABEL: 'location icon',
    PLANS_CLICK_TO_GET_CALLED_NUMBER: 'O llámanos al 600 3904100',
    PLANS_DETAILS_MINUTES_LABEL: 'MINUTOS',
    CHOOSE_YOUR_PLAN: 'Elige tu equipo y plan',
    COLORS_AVAILABLE_SMALL: 'Colores Disponibles',
    PICKUP_INSTORE_NOT_AVAILABLE_IN: 'No disponible en ',
    FALLBACK_SEO_COLLECTION_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    SELLER_AND_SHIPPED_BY: 'y enviado por',
    COMBO_VIRGIN_LABEL: 'Virgin',
    DELIVERY_TYPE_MOB_SUB: 'Compra online o compra en tienda',
    FALLBACK_SEO_BRAND_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    RETURN_POLICY_INFO_SHOES: 'Tienes 30 días desde que lo recibes. ',
    FALLBACK_SEO_BRAND_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FREE_SHIPPING_PRICE: '59990',
    DELIVERYSLOT_CALENDAR_TIME_RANGE: 'Rango horario',
    BV_RECOMMENDED_THRESHOLD: 80,
    RESULTS: 'Resultados',
    NO_PLAN_LABEL:
      'Por ahora no contamos con planes disponibles para este teléfono. Te recomendamos revisar otros teléfonos para que elijas el que más te guste y lo puedas llevar con el plan de tu preferencia.',
    SELECT_VARIANT_WARNING_MESSAGE: 'Selecciona tus opciones para agregar el producto al carro',
    BAG: 'Bolsa',
    ADD_REBRANDING: 'Agregar',
    NEED_HELP_CALL_US_AT_PLAN: '¿Necesitas ayuda para contratar un plan? Llámanos al',
    AVAILABILITY_DELIVERY_TYPE: 'Tipo de entrega',
    ERROR_SERVICE_NO_CREDIT:
      'No existen ofertas disponibles con este operador en la actualidad. ¡Revisa la oferta con otros operadores!',
    SELECTION_SIZE: 'Selecciona Talla',
    NO_PLAN_TITLE: '¡Lo sentimos!',
    OFFER_PRICE_WARRANTY_LABEL: '(Oferta)',
    SURVEY_TEXT_HELP_US: 'Ayúdanos a mejorar tu experiencia',
    PLANS_CLICK_TO_GET_CALLED_TITLE: '¡No te quedes sin tu plan!',
    PLANS_CLICK_TO_GET_CALLED_FORM_INPUT_NUMBER: '+56',
    AVAILABILITY_IMMEDIATE_DELIVERY: 'Entrega inmediata',
    TYPE_C_HOME_DELI_TITLE: 'Disponible para despacho a domicilio',
    FALLBACK_IMAGES_DOMAIN: 'https://media.falabella.com',
  },
  toggles: {
    isSellerInfoVisibleInPDP: true,
    isHomeDeliveryVisibleInPod: false,
    isAddtionalInfoForSodimacEnabledInUI: true,
    isQuantityEdittableEnabled: true,
    isBVQuestionsEnabled: false,
    isShippingLabels404LogsDisabled: false,
    isPictureTagEnabled: true,
    isRedirect301EnabledForPLP: true,
    isNewSearchVisible: true,
    isGenericCalculatorEnabled: true,
    IsSearchExperienceCheckbox: true,
    isMeatStickerApplicableInPDP: true,
    isPickUpFromStoreVisibleInFacet: false,
    isQuantityEditableEnabled: true,
    isOneClickCheckoutEnabled: true,
    isBoomVisibleInPod: false,
    isSellerRucEnabled: true,
    isPickupInStoreVisibleInPDP: true,
    isReaconditionedLinkEnabledPDP: true,
    isHomeDeliveryVisibleInFacet: false,
    shouldSendContextIdToReco: true,
    disableSponsoredProductsPDP: false,
    isStockQuantityVisibleInPDP: false,
    isPromotionsEnabledInPDP: true,
    isInhouseBundleWidgetActive: false,
    PDP_AB_TEST_OVERRIDE_VALUE: '',
    shouldSendUserIdToReco: true,
    isWarrantyTooltipEnabled: true,
    isNewTypeaheadEnabled: true,
    isStoreAvailabilityVisibleInPDP: true,
    isPickUpFromStoreVisibleInPod: false,
    isNewWarrantyOptionsV2Enabled: true,
    isShippingFacetVisible: false,
    isPoliticalIdExists: true,
    isAbCookieEnabled: false,
    isRelatedKeywordEnabled: false,
    showRatingDescription: false,
    isPlansClickToGetCalledEnabled: true,
    isNewBVWriteReviewEnabled: true,
    isAbCookieEnabledBrand: false,
    isPlansEvaluationEnabled: true,
    newDiscountBadge: true,
    isSISExperienceOn: true,
    showListToggleButtons: true,
    isCMRButtonWithPriceVisibleInPDP: true,
    isComboUiEnabled: true,
    isPopOverVisible: true,
    isHomeDeliveryVisibleInPDP: true,
    isShippingAttributesEnabled: false,
    isSellerMute: true,
    isSurveyBarShowninPDP: false,
    isFloorcalculatorEnabled: false,
    isNewTypeaheadEnabledForBeta: true,
    showPricesForSodimacProducts: true,
    isActiveTabAssistentePersonal: false,
    isBestSellerEnabled: true,
    isGeoLocationEnabled: true,
    isRedirect301EnabledForBrand: true,
    isSizeEquivalencyTableVisible: false,
    featureEnableSponsoredDisplay: true,
    isCMRAperturaUrlAddonsEnabled: true,
    isSisSodimacPdpEnabled: false,
    isNewDeliveryOptionsEnabled: true,
    isCatalogV2EndpointActive: true,
    isTypeAheadEnabled: true,
    useImplicitFilterForXLP: false,
    indexAndCrawlSLTPagesToggle: true,
    isMyListsEnabled: true,
    isAbCookieEnabledPLP: false,
    isGlobalCategoryEnabled: true,
    isBreadcrumbsV2Enabled: true,
    isComparativeTableSameSellerEnabled: false,
    isDYEnabled: true,
    disableGeofinderModalForMobile: true,
    isRootCategoryGForFacetCategoriaDisabled: false,
    isSellerBusinessNameEnabled: true,
    isChatBotVisible: false,
    isAbCookieEnabledCLP: false,
    isAbCookieEnabledSLP: false,
    isCustomABTestFlagEnabled: false,
    isPDPShippingModalEnabled: false,
    isCMRCalculatorEnabledPDP: false,
    transversalBannerConfig: '{"home":true,"pdp":false,"xlp":true,"basket":true}',
    isPDPMyListsEnabled: true,
    isReturnPolicyVisibleInPDP: true,
    splitSearchFacetCalls: true,
    isHtmlValidatorEnabled: false,
    isFacetCountHidden: true,
    isPumPriceEnabled: false,
    isAbCookieEnabledSeller: false,
    isPhotoSlurpEnabled: false,
    isCFMediasEnabled: false,
    isComparativeTableEnabled: true,
    isInternationalShippingVisibleInPod: true,
    isWebPushNotificationEnabled: true,
    disableSponsoredProductsSisPDP: false,
    isInternationalBuyVisiblePDP: true,
    isGoogleAdsBannerEnabled: true,
    isBoomVisibleInFacet: false,
    isMeatStickerApplicable: true,
    isShippingFacetWithSwitchVisible: true,
    is3PFreeShippingEnabled: true,
    isQuantitySelectorVisibleInPDP: true,
    identifyBuyAtStore: false,
    isCategoryFacetConfiguredAsGlobalFacet: true,
    isFacetWithSwitchVisible: true,
    isOneClickButtonVisible: false,
    isPidAndPgidValidationDisabled: false,
    isShippingLabelsEnabledInXlp: false,
    isShippingLabelsEnabledInPdp: false,
    isHelplineEnabled: true,
    isRecoV2Enabled: true,
    isNewSizeGroupService: false,
    isCMRAperturaEnabled: true,
    showCustomPlaceHolderForPriceRange: true,
    isSellerPopupEnabled: true,
    disableQuantitySelection: false,
    isAddtionalSellerTextEnabled: true,
    isProductOrchestratorRecoEnabled: false,
    isCartQuantityLimitsEnabled: false,
    freeShippingOnZonesEnabled: true,
    isNewSortingLogicForSponsoredProductsEnabled: true,
    isPodActionButtonEnabled: true,
    isSodimacFaviconTestActivated: false,
    isCMRAperturaInNewPageEnabled: true,
    isAdditionalServiceFilteredForSodimac: false,
    isMultipurposeBadgeApplicable: true,
    isAIExperimentEnabled: true,
    isNewPriceApplicable: true,
    isRelevancyForCollection: true,
    isPDPWidgetGlobalEnabled: true,
    isInHouseReviewSectionEnabled: true,
    isNewWarrantyUIEnabled: true,
    isNewCMRDesignEnabledPDP: false,
    isNormalPriceCrossedOut: true,
    isCustomPDPABTestFlagEnabled: false,
    isNewWarrantyOptionsEnabled: true,
    hasFreeShippingForInternationalProducts: true,
    isEventToggleVisible: false,
    isMKPProductDisabled: false,
    adInjectorIsEnabled: false,
    isUnitForSaleEnabled: true,
  },
  endpoints: {
    APERTURA_URL_VALID_CMR:
      'https://www.falabella.com/falabella-cl/page/CMR-enlinea?staticPageId=28700002&from=bShR3VyENqOK/iZsx/hUW4owR5kjQSQtt/XRCU1Pf3rhnbHrJ75QVFx4rZoioZA7Druyh/M/DtI1I/gPmmejNaiDTql/XJr0Nrso6iy+PPtSAco+SCZAjoRj9QapowiHgPJruBhfVvV0Jdebsu4pvl8k5AR1a6Uf4OACMk5vpUTP/PbJtfiNL84QpaAdHM9kMdlDR4m/CUNHMSWXv8dgA1fqCuR1U4tNoyEocdM3NqzhscbJT1l+tQVaqJ6M8jJ1IUa2dl1wJQOeE1tSzTHfjy4jdLHemq4nxQiVxzWxe5gTfft90rmWuwFgt7Yu/yzvuo61qnL0Db8d8uI2RJTKFQ=&detalleClic=pdpconOU',
    RETURN_POLICY_LINK_URL: 'https://www.falabella.com/falabella-cl/page/excepciones-devoluciones-cambios',
    WORKING_HOURS_TIENDA: 'https://tienda.falabella.com/falabella-cl/page/horarios-tienda-Falabella',
    ATG_AUTH_BASEURL: 'https://www.falabella.com',
    CATALYST_AUTH_BASEURL: 'https://www.falabella.com',
    WISHLISTS_PAGE_URI: 'https://www.falabella.com/falabella-cl/myaccount/myLists',
    BASKET_PAGE_URI_SODIMAC: 'https://www.sodimac.cl/sodimac-cl/basket',
    APERTURA_URL_INVALID_CMR:
      'https://www.falabella.com/falabella-cl/page/CMR-enlinea?staticPageId=28700002&from=bShR3VyENqOK/iZsx/hUW4owR5kjQSQtt/XRCU1Pf3rhnbHrJ75QVFx4rZoioZA7Druyh/M/DtI1I/gPmmejNaiDTql/XJr0Nrso6iy+PPtSAco+SCZAjoRj9QapowiHgPJruBhfVvV0Jdebsu4pvl8k5AR1a6Uf4OACMk5vpUTP/PbJtfiNL84QpaAdHM9kMdlDR4m/CUNHMSWXv8dgA1fqCuR1U4tNoyEocdM3NqzhscbJT1l+tQVaqJ6M8jJ1IUa2dl1wJQOeE1tSzTHfjy4jdLHemq4nxQiVxzWxe5gTfft90rmWuwFgt7Yu/yzvuo61qnL0Db8d8uI2RJTKFQ=&detalleClic=pdpsinOU',
    BASKET_PAGE_URI: 'https://www.falabella.com/falabella-cl/basket',
    EXT_WARRANTY_LIGHTBOX_LINK: '/falabella-cl/page/garantia?staticPageId=11300003',
    WORKING_HOURS_SODIMAC: 'https://sodimac.falabella.com/sodimac-cl/page/tiendas-y-horarios-sodimac',
    WORKING_HOURS_TOTTUS: 'https://tottus.falabella.com/tottus-cl/page/horario_tiendas',
  },
  facetLabels: {
    'f.derived.variant.activeEvent': '<p>CYBER</p>',
    'f.shipping.hd_same_day_zones': '<p>Si compras hasta las&nbsp;<strong>16:00 horas</strong></p>',
    'f.derived.variant.freeShipping': '',
  },
  configurations: {
    adsConfig:
      '{"adProviderUrl":"https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt3ef14bb68a88d66c/ads_desktop_v3.html","xlp":[{"slot":"div-gpt-ad-1681222629103-0","folder":"/21632469435/PLP_Banner_Superior","container":"google-banner-superior-div"},{"slot":"div-gpt-ad-1655991838659-0","folder":"/21632469435/plp_vertical","container":"google-banner-izq-div"},{"slot":"div-gpt-ad-1655991534231-0","folder":"/21632469435/plp_horizontal_desktop","container":"google-banner-inferior-desktop-div"}],"search":[{"slot":"div-gpt-ad-1681222629103-0","folder":"/21632469435/PLP_Banner_Superior","container":"google-banner-superior-div"},{"slot":"div-gpt-ad-1655991838659-0","folder":"/21632469435/plp_vertical","container":"google-banner-izq-div"},{"slot":"div-gpt-ad-1655991534231-0","folder":"/21632469435/plp_horizontal_desktop","container":"google-banner-inferior-desktop-div"}]}',
    disabledStoresSponsoredProducts: '{"stores":["tottus"]}',
    SponsoredDisplayPosition: {
      positions: {
        cat3160: {
          desktop: 3,
          mobile: 1,
        },
      },
    },
  },
};

export default siteConfig;
