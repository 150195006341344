/* eslint-disable prefer-destructuring */
import { ThemeProvider } from '@digital-retail/design-system';
import { Favicons, Provider, ThemeContext } from '@digital-retail/falabella-ui-cross-components';
import {
  getLatLongFromAddressLine,
  getSeoPageTitle,
  isSodimacStandalone,
  isTottusStandalone,
  isWhitelabel,
} from '@digital-retail/store-manager';
import { Container } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../config/constants';
import LayoutContainer from '../containers/Layout/LayoutContainer';
import { LanguageContext } from '../containers/Layout/LayoutContext';
import loadDigitalData from '../external_dependencies/digitalData';
import loadExternalDependencies from '../external_dependencies/index';
import ApplicationContext, { withApplicationContext } from '../utils/ApplicationContext';
import ConfigurationContext from '../utils/ConfigurationContext';
import CookieStorage from '../utils/CookieStorage';
import '../utils/CoreJsImports';
import { getTheme } from '../utils/DesignSystemTheme';
import { getDeviceType, getPlatformType, isInternetExplorer, isSearchBot, isWindow } from '../utils/deviceChecker';
import DigitalDataContext from '../utils/DigitalDataContext';
import { fetchApplicationData } from '../utils/FetchData';
import GlobalDataContext from '../utils/GlobalDataContext';
import LabelsContext from '../utils/LabelsContext';
import _ from '../utils/LodashImports';
import Logger, { attachErrorLogger } from '../utils/Logger';
import MyListsContext from '../utils/MyListsContext';
import { redirectToUrl } from '../utils/RouteHandler';
import { getStoreValue } from '../utils/urlHelper';
import UserContext from '../utils/UserContext';
import WithUrl from '../utils/WithUrl';

const LayoutWithAppContext = withApplicationContext(LayoutContainer);

const nextConfig = getConfig();
const publicRuntimeConfig = (nextConfig && nextConfig.publicRuntimeConfig) || {};
const serverRuntimeConfig = (nextConfig && nextConfig.serverRuntimeConfig) || {};

const isComponentRenderer = (Component) => {
  return Component.name === 'ComponentRenderer' || Component.name === 'ComponentStylesRenderer';
};
const calculateThemes = (initialAppCtx, store) => {
  if (initialAppCtx.disableSISTheme) {
    return undefined;
  }
  if (isWhitelabel({ store })) {
    return _.get(initialAppCtx, 'header.entry.pdp_themes', undefined);
  }
  return _.get(initialAppCtx, 'header.entry.themes', undefined);
};

// comment to prevent CC CSS caching
const App = ({ Component, pageProps, appCtx: initialAppCtx }) => {
  const router = useRouter();
  attachErrorLogger();
  const currentSIS = _.get(initialAppCtx, 'store', '') || 'marketplace';
  const [themeState, setThemeState] = useState(calculateThemes(initialAppCtx, currentSIS));
  const rebrandingModifier = _.get(initialAppCtx, 'siteConfig.textDictionary.REBRANDING_MODIFIER', 'solar');
  useEffect(() => {
    const themes = calculateThemes(initialAppCtx, currentSIS);
    setThemeState(themes);
    const allowedSISRaw = _.get(initialAppCtx, 'siteConfig.textDictionary.DY_ALLOWED_SIS', '');
    const allowedSIS = !!(currentSIS && allowedSISRaw.toLowerCase().split(',').includes(currentSIS));
    loadExternalDependencies({
      tenant: initialAppCtx.regionCode,
      isDYEnabled: _.get(initialAppCtx, 'siteConfig.toggles.isDYEnabled', false),
      allowedSIS,
    });
    Logger.init(initialAppCtx);
    CookieStorage.setHostOnlyCookie({
      name: 'isPoliticalIdExists',
      value: _.get(initialAppCtx, 'siteConfig.toggles.isPoliticalIdExists', false),
      regionCode: initialAppCtx.regionCode,
    });
    const zonesList = _.get(initialAppCtx, 'zones', '').split(',');
    const zones = JSON.stringify(zonesList);
    CookieStorage.setCookie({
      name: 'priceGroupId',
      value: _.get(initialAppCtx, 'priceGroupId', ''),
      regionCode: initialAppCtx.regionCode,
      store: initialAppCtx.store,
    });
    CookieStorage.setCookie({
      name: 'politicalId',
      value: _.get(initialAppCtx, 'politicalId', ''),
      regionCode: initialAppCtx.regionCode,
      store: initialAppCtx.store,
    });
    CookieStorage.setCookie({
      name: 'zones',
      value: zones,
      regionCode: initialAppCtx.regionCode,
      store: initialAppCtx.store,
    });
    let zoneID;
    try {
      zoneID = zonesList.find((z) => {
        return _.get(initialAppCtx, 'zonesInfo.availableZones', {})[z];
      });
    } catch (error) {
      zoneID = zonesList.slice(-1)[0];
    }
    if (!zoneID) {
      zoneID = zonesList.slice(-1)[0];
    }
    const selectedZone = _.get(initialAppCtx, 'zonesInfo.zones', []).find((z) => z.id === zoneID);
    const zoneName = _.get(selectedZone, 'name', constants.DEFAULT_ZONES);
    CookieStorage.setCookie({
      name: 'zoneData',
      value: { zoneID, zoneName },
      regionCode: initialAppCtx.regionCode,
      store: initialAppCtx.store,
    });
    const comuna = _.get(initialAppCtx, 'comuna', {});
    const isDefaultComuna = _.get(initialAppCtx, 'isDefaultComuna', false);
    if (comuna.default || isDefaultComuna) {
      CookieStorage.setCookie({
        name: 'IS_ZONE_SELECTED',
        value: false,
        regionCode: initialAppCtx.regionCode,
        store: initialAppCtx.store,
      });
      CookieStorage.setCookie({
        name: 'userSelectedZone',
        value: 'defaultZone',
        regionCode: initialAppCtx.regionCode,
        store: initialAppCtx.store,
      });
    }
    const COOKIES_PAIR_TO_REMOVE = _.get(initialAppCtx, 'siteConfig.textDictionary.COOKIES_PAIR_TO_REMOVE', '');

    CookieStorage.removeCookiePairs(COOKIES_PAIR_TO_REMOVE, initialAppCtx.regionCode, initialAppCtx.store);
  }, []);

  useEffect(() => {
    loadDigitalData(router.route);
  }, [router.route]);

  const commerceOriginData = { name: 'PDP', view: 'Product' };
  const isTottusSA = isTottusStandalone(initialAppCtx.store);
  const isSodimacSA = isSodimacStandalone(initialAppCtx.store);

  return (
    <Container>
      <DigitalDataContext.Provider>
        <ConfigurationContext.Provider config={publicRuntimeConfig}>
          <ApplicationContext.Provider appCtx={initialAppCtx}>
            <Provider
              tenant={initialAppCtx.regionCode}
              baseUrl={_.get(
                initialAppCtx,
                'header.entry.header_configs[0].links.catalystbaseurl',
                'https://www.falabella.com'
              )}
              deviceType={initialAppCtx.deviceType}
              enableOldStoreInStoreHeaders={initialAppCtx.disableSISTheme}
              commerceOriginData={commerceOriginData}
              isSodimacStandAloneSite={isSodimacSA}
              isTottusStandAloneSite={isTottusSA}
            >
              <LabelsContext.Provider
                labels={(initialAppCtx.siteConfig && initialAppCtx.siteConfig.textDictionary) || {}}
              >
                <Head>
                  <title>{getSeoPageTitle({ store: initialAppCtx.store })}</title>
                  {_.get(initialAppCtx, 'isAbtastyEnabled', false) && (
                    <script src="https://try.abtasty.com/c49e3840b20a6fe9ebdf30eaf2ec2745.js" />
                  )}
                  <meta
                    name="viewport"
                    content="initial-scale=1.0, width=device-width, maximum-scale=1,user-scalable=no"
                  />
                  <meta name="theme-color" content="#FFFFFF" />
                  <meta charset="iso-8859-1" />
                  <meta name="HandheldFriendly" content="True" />
                  <meta name="MobileOptimized" content="320" />
                  <meta name="format-detection" content="telephone=no" />
                  <meta name="apple-mobile-web-app-status-bar-style" content="black" />
                  <meta httpEquiv="pragma" content="cache" />
                  <meta property="og:type" content="article" />
                  <meta name="apple-mobile-web-app-title" content={getSeoPageTitle({ store: initialAppCtx.store })} />
                  <meta name="language" content="ES" />
                  <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                  <meta name="google-site-verification" content="1VMve7ONbnxKqYZ1xuoXrvmrAEs8rMkCFsIfcNUp_Cs" />
                  {Favicons.render({
                    tenant: initialAppCtx.regionCode,
                    store: initialAppCtx.store,
                  })}
                  <link rel="preconnect" href="//cdn.dynamicyield.com" crossOrigin />
                  <link rel="preconnect" href="//st.dynamicyield.com" crossOrigin />
                  <link rel="preconnect" href="//rcom.dynamicyield.com" />
                  <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
                  <link rel="dns-prefetch" href="//st.dynamicyield.com" />
                  <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />
                  <link rel="preconnect" href="https://gateway.foresee.com" />
                  <link rel="preconnect" href="https://analytics.foresee.com" />
                  <link rel="preconnect" href="https://falabella.demdex.net" crossOrigin />
                  <link rel="preconnect" href="https://connect.facebook.net" crossOrigin />
                  <link rel="preconnect" href="https://www.facebook.com" crossOrigin />
                  <link rel="preconnect" href="https://stats.g.doubleclick.net" crossOrigin />
                </Head>
                <UserContext.Provider>
                  <LanguageContext.Provider>
                    <GlobalDataContext.Provider>
                      <LayoutWithAppContext>
                        <MyListsContext.Provider appCtx={initialAppCtx}>
                          <ThemeContext.Provider themes={themeState} store={currentSIS}>
                            <ThemeProvider theme={getTheme(rebrandingModifier)}>
                              <Component {...pageProps} />
                            </ThemeProvider>
                          </ThemeContext.Provider>
                        </MyListsContext.Provider>
                      </LayoutWithAppContext>
                    </GlobalDataContext.Provider>
                  </LanguageContext.Provider>
                </UserContext.Provider>
              </LabelsContext.Provider>
            </Provider>
          </ApplicationContext.Provider>
        </ConfigurationContext.Provider>
      </DigitalDataContext.Provider>
    </Container>
  );
};

App.getInitialProps = async ({ Component, router, ctx }) => {
  try {
    if (!ctx.query.site) {
      const url = WithUrl(404);
      redirectToUrl(url, ctx.res);
      return {};
    }
    const isClientSide = isWindow();
    const cookie = isClientSide ? document.cookie : ctx.req.headers.cookie;
    const cookies = CookieStorage.parseCookies(cookie);
    const { headers } = ctx.req || {};
    const reqUrl = isClientSide ? window.location.pathname + window.location.search : ctx.req.url;
    const { host } = headers || {};
    const buildId = (headers && headers['x-next-build-id']) || '';
    const appCtx = {
      subdomain: ctx.query && ctx.query.subdomain,
      tenant: (ctx.query && ctx.query.site) || '',
      host,
      isComponentRequest: isComponentRenderer(Component),
      deviceType: !isClientSide ? getDeviceType(ctx.req.headers) : getDeviceType(),
      isInternetExplorer: !isClientSide
        ? isInternetExplorer(ctx.req.headers['user-agent'])
        : isInternetExplorer(navigator.userAgent),
      busy: 0,
      buildId,
      ssrCookies: cookie,
      tracingHeaders: {},
      isSearchBot: !isClientSide && ctx.req.headers['user-agent'] && isSearchBot(ctx.req.headers['user-agent']),
      platformType: !isClientSide ? getPlatformType(ctx.req.headers) : getPlatformType(window.navigator.userAgent),
      url: reqUrl,
    };
    appCtx.regionCode = `${appCtx.tenant.substr(appCtx.tenant.indexOf('-') + 1, 2)}`;
    appCtx.store = (ctx.query && ctx.query.store && getStoreValue(ctx.query.store)) || null;

    Logger.init(appCtx);
    const getHost = (env, tenant, url) => {
      if (tenant !== 'cl') {
        return `https://${env}.falabella.com.${tenant}${url}`;
      }
      return `https://${env}.falabella.com${url}`;
    };

    publicRuntimeConfig.API_HOST = getHost(
      publicRuntimeConfig.env,
      appCtx.regionCode,
      publicRuntimeConfig.BROWSE_ENDPOINT
    );

    publicRuntimeConfig.CUSTOMER_LOCATION_HOST = getHost(
      publicRuntimeConfig.env,
      appCtx.regionCode,
      publicRuntimeConfig.CL_ENDPOINT
    );

    publicRuntimeConfig.CONTENT_API_HOST = getHost(
      publicRuntimeConfig.env,
      appCtx.regionCode,
      publicRuntimeConfig.CONTENT_ENDPOINT
    );

    const { env, CLUSTER_API_HOST, CLUSTER_CUSTOMER_LOCATION_HOST, CLUSTER_CONTENT_API_HOST } = publicRuntimeConfig;

    if (env === 'local') {
      publicRuntimeConfig.API_HOST = CLUSTER_API_HOST;
      publicRuntimeConfig.CUSTOMER_LOCATION_HOST = CLUSTER_CUSTOMER_LOCATION_HOST;
      publicRuntimeConfig.CONTENT_API_HOST = CLUSTER_CONTENT_API_HOST;
    }

    if (!isClientSide && ctx.res && !(ctx.res.finished || ctx.res.headersSent)) {
      constants.TRACING_HEADERS.forEach((headerName) => {
        if (headers && headers[headerName]) {
          ctx.res.setHeader(headerName, headers[headerName]);
          appCtx.tracingHeaders[headerName] = headers[headerName];
        }
      });
    }

    let parsedZones = [];
    const isValidZones = () => {
      try {
        parsedZones = JSON.parse(cookies.zones);
        return true;
      } catch {
        return false;
      }
    };
    // availableZones conditions to be added to check if the zone is valid

    const isvalid = (data) => data && data !== 'undefined' && data !== 'false';

    appCtx.pid = isvalid(cookies.politicalId) ? cookies.politicalId : 'default';
    if (isTottusStandalone(appCtx.store)) {
      const addressLines = isvalid(cookies.addressLines) ? cookies.addressLines : '';
      const latLong = getLatLongFromAddressLine(addressLines);
      appCtx.latLong = latLong;
    }
    const appDataFetcher = fetchApplicationData(publicRuntimeConfig, serverRuntimeConfig, appCtx, ctx.query);

    let services = [];

    if (!isClientSide) {
      services = ['siteConfig', 'transversalBanner', 'header', 'footer', 'taxonomy', 'zonesInfo', 'sisNavigationMenu'];
      if (appCtx.regionCode !== 'ar') {
        services.push('comuna');
      }
      if (appCtx.store) {
        services.push('sisNavigationMenu');
      }
    }

    const servicesData = await appDataFetcher.fetch(services);

    services.forEach((service, i) => {
      appCtx[service] = servicesData[i];
    });

    if (!isClientSide && appCtx.siteConfig && !_.get(appCtx, 'siteConfig.toggles.isPoliticalIdExists', false)) {
      const service = ['zonesInfo'];
      const serviceData = await appDataFetcher.fetch(service);
      const [zonesInfo] = serviceData;
      appCtx.zonesInfo = zonesInfo;
    }

    if (!isClientSide && appCtx.siteConfig) {
      appCtx.siteConfig.textDictionary.ITEM_ADDED_IN_CART = _.get(
        appCtx,
        'siteConfig.textDictionary.ITEM_ADDED_IN_CART_MKP',
        'Lo que llevas en tu Carro'
      );
    }

    appCtx.isOrangeHeader = _.get(cookies, 'ORANGE_COLOR_AB', '') === 'true';
    appCtx.isSISLevel2Header = _.get(cookies, 'SISLEVEL2_HEADER_AB', '') === 'true';
    appCtx.comuna = await appCtx.comuna;
    const comuna = _.get(appCtx.comuna, 'data', undefined);
    const isUserSelectedZone = cookies.userSelectedZone === 'userselected';
    appCtx.isUserSelectedZone = isUserSelectedZone;
    if (comuna) {
      const zones = comuna.zones || [];
      appCtx.priceGroupId = comuna.priceGroupId;
      appCtx.politicalId = comuna.politicalId;
      appCtx.zones = zones.join();
      appCtx.isDefaultComuna = _.get(appCtx.comuna, 'default', false) ? true : !isUserSelectedZone;
    } else {
      appCtx.priceGroupId = cookies.priceGroupId;
      appCtx.politicalId = cookies.politicalId;
      appCtx.zones = isValidZones() ? parsedZones.join() : '';
    }

    const themeCookie = cookies.EXP_SIS || cookies.exp_sis;
    appCtx.disableSISTheme = themeCookie === 'old';

    appCtx.isAbtastyEnabled = publicRuntimeConfig.ABTASTY_ENABLED === 'true';
    appCtx.isVwoEnabled = publicRuntimeConfig.VWO_ENABLED === 'true';

    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ config: publicRuntimeConfig }, ctx, appCtx, router);
    }

    return {
      pageProps,
      appCtx,
    };
  } catch (err) {
    const tenant = ctx.query.site;
    Logger.error(`error in _app.js ${err}`);
    const url = constants.APP_COMP_ERR_PAGES[tenant];
    redirectToUrl(url, ctx.res);
    return {};
  }
};

App.propTypes = {
  pageProps: PropTypes.object,
  Component: PropTypes.func,
  appCtx: PropTypes.object.isRequired,
};

App.defaultProps = {
  pageProps: {},
  Component: () => {},
};

export default App;
