import getConfig from 'next/config';
import { createScriptTag, appendScriptsToHead } from '../../utils/DOMUtil';
import constants from '../../config/constants';

const nextConfig = getConfig();
let { env } = (nextConfig && nextConfig.publicRuntimeConfig) || {
  env: 'dev',
};
if (env === 'uat') {
  env = 'beta';
}

const loadDigitalData = (route) => {
  let scriptURL = null;
  if (['/product'].includes(route)) {
    scriptURL = constants.DIGITAL_DATA_MANAGER_PDP_SCRIPT[env];
  }
  if (['/noResult'].includes(route)) {
    scriptURL = constants.DIGITAL_DATA_MANAGER_NO_RESULT_SCRIPT[env];
  }
  if (scriptURL) {
    const dynamicScript = createScriptTag({
      src: scriptURL,
      id: 'script-digital-data-manager',
    });
    appendScriptsToHead(dynamicScript);
  }
};

export default loadDigitalData;
