import createContainer from 'constate';
import { useRouter } from 'next/router';
import constants from '../../config/constants';

const LanguageContext = createContainer(
  () => {
    const router = useRouter();
    const language = router?.query?.[constants.lang] || 'cl';
    // eslint-disable-next-line
    const translations = require(`../../config/translations/labels-${language}`);
    return translations;
  },
  () => []
);

export { LanguageContext };
